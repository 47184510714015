<template>
    <div class="topic content">
        <Bread :breads="breads"></Bread>
        
        <SearchBar @onSearch="search"></SearchBar>
        <div class="topic-list" v-loading="loading">
            <div v-for="(topic, index) in topicList.list" :key="index" class="topic-item" @click="gotoDetail(topic.id)">
                <div class="topic-image">
                    <img :src="topic.imgUrl"/>
                </div>
                <div class="topic-info">
                    <div class="topic-name" v-html="matchKey(topic.title)"></div>
                    <div class="topic-desc" v-html="matchKey(topic.abstrac)"></div>
                    <div class="topic-date">时间：{{topic.dateForMat}}</div>
                </div>
            </div>
        </div>
        <Pager @pageChange="pageChange" class="topic-pager" :currentPage="currentPage" v-if="topicList.totalPage" :totalPage="topicList.totalPage"></Pager>
    </div>
</template>

<script>
import Bread from "../components/common/Bread"
import Pager from "../components/common/Pager"
import SearchBar from "../components/common/SearchBar"
import {ref, onMounted, getCurrentInstance} from 'vue'
export default {
    name: "TopicDetail",
    data(){
        return {
            breads:[{
                text:'首页',
                name: 'Index',
                path: '/index'
            }, {text: '专题服务',
                path: ''},
            ],
        }
    },
    setup(){
        const {proxy} = getCurrentInstance();
        const topicList = ref({})
        const loading = ref(false)
        const currentPage = ref(1)
        const searchKey = ref('')

        function getTopicList(){
            loading.value = true;
            const url = '/gdsti2/web/a/web/specialList'
            proxy.$http.post(url, {searchVal: searchKey.value, limit: 5, offset: (currentPage.value-1)*5})
            .then(res=>{
                loading.value = false
                if(res.data.state == 0){
                     for(var i = 0 ; i < res.data.result.list.length; i++){
                         res.data.result.list[i].imgUrl = proxy.$getImage(res.data.result.list[i].img);
                     }
                     topicList.value = res.data.result;
                }
            }).catch(err=>{
                loading.value = false
            })

        }

        onMounted(()=>{
            getTopicList();
        })

        return {topicList, loading, currentPage, searchKey, getTopicList}
    },
    components:{Bread, Pager, SearchBar},
    methods:{
        pageChange:function(index){
            console.log(index);
            this.currentPage = index;
            this.getTopicList();
        },
        search:function(key){
            console.log(key)
            this.searchKey = key;
            this.currentPage = 1;
            this.getTopicList();
        },
        gotoDetail: function(id){
            this.$router.push({name: 'TopicDetail', path: '/topicDetail', query:{id: id}})
        },
        matchKey(val){
            if(this.searchKey){
                let keyword = this.searchKey;
                let replaceReg = new RegExp(keyword, 'ig')
                let replaceString = `<span style="color: #E02020;">${keyword}</span>`
                return val.replace(replaceReg, replaceString);
            }else{
                return val;
            }
        }
    }
}
</script>
<style lang="less" scoped>
.topic-pager{
    margin-top: 34px;
    margin-bottom: 34px;
}
.topic-list{
    .topic-item:hover{
        background: #E7E7E7;
    }
    .topic-item{
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px dashed #E7E7E7;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;

        .topic-image{
            display: inline-block;
            width: 240px;
            height: 100px;
            margin-right: 14px;

            img{
                width: 240px;
                height: 100px;
            }
        }
        .topic-info{
            .topic-name{
                font-family: SourceHanSansCN-Medium;
                font-size: 20px;
                color: rgba(0,0,0,0.85);
                letter-spacing: 0;
                text-align: justify;
                
            }
            .topic-desc,.topic-date{
                font-family: SourceHanSansCN-Regular;
                font-size: 12px;
                color: rgba(0,0,0,0.50);
                letter-spacing: 0;
                text-align: justify;
                line-height: 16px;
                margin-top: 10px;
            }
            .topic-desc{
                -webkit-line-clamp: 2;
                line-clamp: 2;
                text-overflow: ellipsis;
                
                
                width: 900px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }
}
</style>
